import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import UserTiersNavigation from '@launchpad/modules/users/user-tiers/UserTiersNavigation';
import UsersNavigation from './UsersNavigation';
import Users from './Users';
// import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'users.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: '/users',
        permission: 'users.*',
        children: [
          {
            label: 'Users',
            icon: 'users',
            path: '/users',
            permission: 'users.*'
          },
          {
            label: 'User tiers',
            icon: 'users',
            path: '/user-tiers',
            permission: 'userTiers.*'
          }
        ]
      }
    ];
  }

  getRoutes() {
    return [
      <AppRoute exact path="/users" component={Users} />,
      <AppRoute path="/user-details" component={UsersNavigation} />,
      <AppRoute path="/user-tiers" component={UserTiersNavigation} />
      // <AppRoute path="/users/bulk-load" component={BulkLoadDashboard} />
    ];
  }
}
