import React from 'react';

export default class AppTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    const stateUpdate = {};

    if (props.activeTab && !state.activeTab) {
      stateUpdate.activeTab = props.activeTab;
      if (props.forceTabRestart) {
        window.localStorage.setItem('APP_TABS_ACTIVE_TAB', state.activeTab);
      }
    }

    return stateUpdate;
  }

  /**
   * Called on tab click
   * @param {Object} tab Clicked tab
   */
  tabClick(tab) {
    this.setState(
      {
        activeTab: tab.id
      },
      () => {
        // Remove get params from url to prevent forwarding page and limit to other tabs
        const url = window.location.href.split('?')[0];
        window.history.pushState({}, '', `${url}`);

        this.props.onTabChange(this.state.activeTab);
        window.localStorage.setItem(
          'APP_TABS_ACTIVE_TAB',
          this.state.activeTab
        );
      }
    );
  }

  /**
   * Render
   */
  render() {
    const { tabs } = this.props;
    let activeTab = tabs[0];

    // const activeTab = this.state.activeTab
    //   ? tabs.find(x => x.id === this.state.activeTab)
    //   : tabs[0];

    const storeTab = window.localStorage.getItem('APP_TABS_ACTIVE_TAB');

    if (storeTab && tabs.find(x => x.id === storeTab) !== undefined) {
      activeTab = tabs.find(x => x.id === storeTab);
    }

    // Get active
    const tabsElements = this.tabElements || [];
    tabs.map((tab, index) => {
      const tabElement = (
        <li
          key={`tab${index}`}
          className={activeTab.id === tab.id ? 'active' : ''}
        >
          <a href="javascript:void(0)" onClick={() => this.tabClick(tab)}>
            <i className={`fa fa-fw fa-${tab.icon}`} /> {tab.title}
          </a>
        </li>
      );
      tabsElements.push(tabElement);
    });
    this.tabElements = this.tabElements;

    return (
      <div>
        <nav>
          <ul className="dashboard-management-nav customers-dashboard-nav">
            {tabsElements}
          </ul>
        </nav>
        {activeTab.component}
      </div>
    );
  }
}

AppTabs.defaultProps = {
  onTabChange: () => {}
};
