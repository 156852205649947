/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Api from '@launchpad/logic/api/Api';
import Chart from 'chart.js';
import { LoadingOverlay } from '@launchpad';

function TransactionsWidget(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Chart.defaults.global.defaultFontColor = 'white';
    // Load data and initialize graph
    _loadData();
  }, []);

  const _loadData = async () => {
    const result: any = await Api.call('admin/transactions/stats/trend', 'GET');

    if (result.success) {
      if (result.data) {
        setLoading(false);
        _renderChart(result.data);
      }
    }
  };

  const _renderChart = (data: {
    labels: Array<string>;
    data: Array<string>;
  }) => {
    const barChartConfig: any = {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: '# of Transactions',
            data: data.data,
            backgroundColor: 'rgba(0, 174, 239, 0.5)',
            borderColor: 'rgba(0, 174, 239, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };

    const canvas = document.getElementById(
      'barChartConfig'
    ) as HTMLCanvasElement | null;

    const ctx3 = canvas?.getContext('2d') as CanvasRenderingContext2D;

    window.barChartConfig = new Chart(ctx3, barChartConfig);
  };

  return (
    <div className="widget">
      <div className="dark-blur" />
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-bar-chart" aria-hidden="true" /> Transactions
        </h3>
      </div>
      <div className="widget-inner" style={{ padding: '10px' }}>
        {loading ? (
          <LoadingOverlay />
        ) : (
          <div style={{ width: '100%', height: '100%' }}>
            <canvas
              id="barChartConfig"
              style={{ width: '100%', height: '300px' }}
              width="600"
              height="300"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionsWidget;
