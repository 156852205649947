import React from 'react';
import { ModalDialog } from '@launchpad';
import ReactJson from 'react-json-view';

const SisDetailsModal = ({ isOpen, onClose, items }) => {
  return (
    <ModalDialog show={isOpen} onClose={onClose} style={{ maxWidth: 800 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div>
          <ReactJson
            name="SIS Data"
            style={{ maxHeight: 600, overflowY: 'auto', width: 750 }}
            src={items}
            theme="google"
            displayObjectSize={false}
            displayDataTypes={false}
            collapsed={1}
            enableClipboard={false}
          />
        </div>
        <button
          type="button"
          onClick={() => onClose()}
          className="button-info"
          style={{ marginTop: 20 }}
        >
          Close
        </button>
      </div>
    </ModalDialog>
  );
};

export default SisDetailsModal;
