import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class OrganisationModel extends BaseModel {
  getMethod() {
    return 'GET';
  }

  getListUrl() {
    return 'admin/organisations';
  }

  getDetailsUrl(id) {
    return `admin/organisations/${id}`;
  }

  async getOrganisationUserDocument(ownerId, uboId) {
    const result = await Api.call(
      `admin/user/${ownerId}/document-submission/${uboId}`,
      'GET'
    );
    return result.data;
  }

  async triggerCheck(
    ownerId,
    uboId,
    uboName,
    checkType,
    profileId,
    organisationId
  ) {
    const result = await Api.call(`admin/flik/user/w2-actions`, 'POST', {
      userId: ownerId,
      type: checkType,
      internalReference: uboId,
      name: uboName,
      profileId,
      organisationId
    });
    return result.data;
  }

  assignData = (arrayOfResponses, result, index) => {
    arrayOfResponses[index] = result.data?.length > 0 ? result.data[0] : [];
  };

  async getOrganisationUboCheckInformation(id, uboId) {
    const arrayOfResponses = [];
    const kycResult = await Api.call(
      `admin/flik/user/${id}/KYC/w2-responses/${uboId}`,
      'GET'
    );
    const pepResult = await Api.call(
      `admin/flik/user/${id}/PEP/w2-responses/${uboId}`,
      'GET'
    );
    const sisResult = await Api.call(
      `admin/flik/user/${id}/SIS/w2-responses/${uboId}`,
      'GET'
    );
    const compareResult = await Api.call(
      `admin/flik/user/${id}/COMPARE/w2-responses/${uboId}`,
      'GET'
    );
    const detailsUBOCheck = await Api.call(
      `admin/flik/user/${id}/DETAILS_CHECK/w2-responses/${uboId}`,
      'GET'
    );

    this.assignData(arrayOfResponses, kycResult, 0);
    this.assignData(arrayOfResponses, pepResult, 1);
    this.assignData(arrayOfResponses, sisResult, 2);
    this.assignData(arrayOfResponses, compareResult, 3);
    this.assignData(arrayOfResponses, detailsUBOCheck, 4);

    // arrayOfResponses.push(
    //   ...kycResult.data,
    //   ...pepResult.data,
    //   ...sisResult.data,
    //   ...compareResult.data
    // );
    return arrayOfResponses;
  }

  async getOrganisationById(organisationId) {
    const result = await Api.call(
      `admin/organisations/${organisationId}`,
      'GET'
    );
    return result.data;
  }

  async changeStatusKYC(organisationId, status) {
    const result = await Api.call(
      `admin/organisations/${organisationId}`,
      'PATCH',
      {
        kybStatus: status
      }
    );
    return result.data;
  }

  async changeUserStatusKYC(organisationId, user) {
    const result = await Api.call(
      `admin/organisations/${organisationId}`,
      'PATCH',
      {
        additionalData: JSON.stringify({
          kybDirectors: [{ ...user }]
        })
      }
    );
    return result.data;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'address.country',
        label: 'Country',
        sortable: false,
        type: 'field'
      },
      {
        name: 'registrationNumber',
        label: 'Registration Number',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
