import React from 'react';
import { ModalDialog } from '@launchpad';
import { camelCaseToHumanReadable } from '@launchpad/util/StringHelper';

const PepDetailsModal = ({ isOpen, onClose, items }) => {
  const keys = items.length > 0 ? Object.keys(items[0]) : [];

  return (
    <ModalDialog
      show={isOpen}
      onClose={onClose}
      style={{ maxWidth: 1000 }}
      customContentStyle="custom-pep-modal"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <table className="table">
          <tbody>
            <tr className="tr-dark-bg">
              {keys.map(key => {
                return <td>{camelCaseToHumanReadable(key)}</td>;
              })}
            </tr>
            {items.map(item => {
              return (
                <tr>
                  {keys.map(key => {
                    return <td>{item[key].toString()}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          type="button"
          onClick={() => onClose()}
          className="button-info"
          style={{ marginTop: 20 }}
        >
          Close
        </button>
      </div>
    </ModalDialog>
  );
};

export default PepDetailsModal;
