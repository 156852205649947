/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ModalDialog } from '@launchpad';
import ToastHelper from '@launchpad/util/ToastHelper';
import React, { useEffect, useState } from 'react';
import UsersModel from '../../../models/UsersModel';

const EditPhoneModal = ({
  visible,
  userId,
  phoneNumber,
  onClose,
  onSuccess
}) => {
  let phone = '';
  if (phoneNumber) {
    if (phoneNumber.includes('|')) {
      phone = phoneNumber.split('|');
    } else {
      phone = phoneNumber.split(' ');
    }
  }

  const [mobileNumberShort, setMobileNumberShort] = useState(phone?.[0] || '+');
  const [mobileNumber, setMobileNumber] = useState(phone?.[1] || '');

  useEffect(() => {
    // Always add plus at the beginning
    if (mobileNumberShort && !mobileNumberShort.startsWith('+')) {
      setMobileNumberShort(`+${mobileNumberShort}`);
    }
  }, [mobileNumberShort]);

  function isNumber(value) {
    const regex = /^\d+$/;
    return regex.test(value);
  }

  function onSubmit() {
    // Remove plus from the beginning
    let parsedMobileNumberShort = mobileNumberShort;
    if (mobileNumberShort && mobileNumberShort.startsWith('+')) {
      parsedMobileNumberShort = mobileNumberShort.slice(1);
    }

    if (
      !parsedMobileNumberShort ||
      !mobileNumber ||
      !isNumber(parsedMobileNumberShort) ||
      !isNumber(mobileNumber)
    ) {
      ToastHelper.show('Please enter a valid phone number');
      return;
    }
    const newPhone = `${parsedMobileNumberShort}|${mobileNumber}`;
    new UsersModel()
      .changeUserPhoneNumber(userId, newPhone)
      .then(() => {
        ToastHelper.show(
          'You successfully changed the phone number',
          'success'
        );
        onSuccess();
      })
      .catch(() => {
        ToastHelper.show('Something went wrong. Please try again later.');
      });
  }

  return (
    <ModalDialog
      show={visible}
      onClose={() => onClose()}
      customContentStyle="edit-phone-modal"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p>Please provide a new mobile number:</p>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <input
            value={mobileNumberShort}
            type="text"
            className="input"
            onChange={e => setMobileNumberShort(e.target.value)}
            style={{
              maxWidth: 60,
              marginRight: 10,
              backgroundColor: 'transparent',
              color: 'white',
              border: '1px solid #fff',
              boxShadow: 'none',
              borderRadius: 5,
              padding: 5
            }}
            maxLength={5}
          />
          <input
            value={mobileNumber}
            type="text"
            className="input"
            placeholder=""
            onChange={e => setMobileNumber(e.target.value)}
            style={{
              marginRight: 10,
              backgroundColor: 'transparent',
              color: 'white',
              border: '1px solid #fff',
              boxShadow: 'none',
              borderRadius: 5,
              padding: 5
            }}
          />
        </div>
        <div>
          <button
            type="button"
            className="button"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button-danger"
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default EditPhoneModal;
