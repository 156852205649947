import { AppConfig } from '@launchpad/types';

const config: AppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  ignorePermissions: true,
  policiesEnabled: false,
  twoFa: true
};

export default config;
