import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import CustomerBasicInfo from './tabs/CustomerBasicInfo';
import UserAccounts from './tabs/UserAccounts';
import KycInfo from './tabs/KycInfo';
import UserTransactions from './tabs/UserTransactions';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import UsersDevicesList from '../../../../../@launchpad/modules/users/user-devices/pages/list/UsersDevicesList';
import Logger from '../../../../../@launchpad/util/Logger';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: 'basic'
    };
  }

  componentDidMount() {
    this._getUserDetails();
  }

  getTabs(user) {
    const basicInfo = (
      <CustomerBasicInfo onRefresh={() => this._getUserDetails()} user={user} />
    );
    const isConsumer = user.userRoles?.includes('consumer');
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      isConsumer && {
        id: 'kyc',
        icon: 'file-text',
        title: 'KYC Info',
        component: (
          <KycInfo
            onRefresh={() => this._getUserDetails()}
            user={user}
            {...this.props}
          />
        )
      },
      {
        id: 'devices',
        icon: 'mobile',
        title: 'Devices',
        component: <UsersDevicesList user={user} {...this.props} />
      },
      {
        id: 'accounts',
        icon: 'address-card',
        title: 'Accounts',
        component: <UserAccounts user={user} {...this.props} />
      },
      {
        id: 'transactions',
        icon: 'exchange',
        title: 'User Transactions',
        component: <UserTransactions user={user} {...this.props} />
      }
    ].filter(Boolean);
    return allTabs;
  }

  async _getUserDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      const userRolesResult = await Api.call(
        `admin/user-roles?userId=${id}`,
        'GET'
      );

      const userRoles = userRolesResult.data?.map(ur => ur.role?.name) || [];
      this.setState({
        user: { ...result.data, userRoles }
      });
    } catch (e) {
      Logger.log('Loading error', e);
    }

    try {
      const result2 = await Api.call(
        `log/error?userId=${id}&vendor=MODULR`,
        'GET'
      );
      this.setState(prevState => ({
        ...prevState,
        user: { ...prevState.user, userErrors: result2.data.messages }
      }));
    } catch (e) {
      Logger.log('Loading error', e);
    }
  }

  _renderContent(userDetails) {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Users',
        link: '/users'
      }
    ]);
    if (userDetails.firstName) {
      BreadcrumbHelper.addCrumb(
        `${userDetails.firstName} ${userDetails.lastName}`
      );
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> User Info:
          <span className="ml-2">
            {userDetails.firstName} {userDetails.lastName}
          </span>
        </h1>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(userDetails)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.user)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object
};
