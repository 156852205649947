export const toHumanString = value => {
  if (!value) return value;

  const camelMatch = /([A-Z])/g;
  const underscoreMatch = /_/g;

  const camelCaseToSpaces = value.replace(camelMatch, ' $1');
  const underscoresToSpaces = camelCaseToSpaces.replace(underscoreMatch, ' ');
  const caseCorrected =
    underscoresToSpaces.charAt(0).toUpperCase() +
    underscoresToSpaces.slice(1).toLowerCase();

  return caseCorrected;
};

export const camelCaseToHumanReadable = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function(s) {
    return s.toUpperCase();
  });
};

export const renderUboType = type => {
  switch (type?.toLowerCase()) {
    case 'director':
      return 'Director';
    case 'shareHolder':
      return 'Shareholder';
    default:
      return '-';
  }
};

export const getAddressFromObject = address => {
  const parts = [
    address.addressOne,
    address.city,
    address.postCode || address.postcode
  ].filter(Boolean);

  return parts.join(', ');
};
